import React from 'react';

import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepIcon, { stepIconClasses } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

import { SmallText } from '../../../ui/EscrowTypography';

const TransactionMiniStepConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: '-10px',
  marginRight: '-10px',
  width: 'auto',
  top: '20px',
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondaryLight.dark,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondaryLight.dark,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mono.main,
    borderWidth: 2,
    borderRadius: 1,
  },
}));

const TransactionMiniStepIcon = styled(StepIcon)(({ theme }) => ({
  color: '#FFF',
  border: '1px solid',
  borderColor: theme.palette.mono.main,
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  [`& .${stepIconClasses.text}`]: {
    fill: theme.palette.secondary.dark,
  },
  [`&.${stepIconClasses.completed}`]: {
    color: theme.palette.success.main,
    border: '0',
  },
  [`&.${stepIconClasses.active}`]: {
    color: theme.palette.secondaryLight.dark,
    padding: '3px',
    width: '32px',
    height: '32px',
    borderColor: theme.palette.secondaryLight.dark,
    [`& .${stepIconClasses.text}`]: {
      fill: '#FFF',
    },
  },
}));

const TransactionMiniStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
    backgroundColor: '#FFF',
    zIndex: 1,
  },
}));

const TransactionMiniStepper = ({ steps }) => {
  const activeStep = steps.find((step) => step.inProgress === true);
  return (
    <Stack spacing={1}>
      <SmallText variant="bold">{activeStep ? activeStep.title : 'Transaction Closed'}</SmallText>
      <Stepper
        data-testid="transaction-mini-stepper"
        orientation="horizontal"
        connector={<TransactionMiniStepConnector />}
      >
        {steps.map((step, idx) => {
          const stepStyle = {};
          if (idx === 0) {
            stepStyle.paddingLeft = '0';
          } else if (idx === steps.length - 1) {
            stepStyle.paddingRight = '0';
          }
          return (
            <Step
              data-testid={step.title}
              key={step.title}
              active={step.inProgress === true}
              completed={step.completed === true}
              sx={stepStyle}
            >
              <TransactionMiniStepLabel StepIconComponent={TransactionMiniStepIcon} />
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

export default TransactionMiniStepper;
