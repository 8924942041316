import { Box, DialogActions, DialogContent, DialogTitle, Dialog, TextField } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import { usePatchTransactionMutation } from '../../transactionApi';
import { useTheme } from '@mui/material/styles';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

export const CancelTransactionContent = ({ transactionId, handleClose, handleBack }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [textInput, setTextInput] = useState('');
  const [dropdownInput, setDropdownInput] = useState('');
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const [errorMsg, setErrorMsg] = useState('');

  const handleInput = (event) => {
    setTextInput(event.target.value);
  };

  const handleDropdownInput = (event) => {
    setDropdownInput(event.target.value);
  };

  const _handleBack = () => {
    if (isLoading) {
      return;
    }
    handleBack();
  };

  const cancellationReason = dropdownInput === 'Other (Please specify)' ? textInput : dropdownInput;

  const handleCancel = async () => {
    try {
      await patchTransaction({
        id: transactionId,
        action: 'cancel',
        ...(cancellationReason && {
          cancel_information: { cancellation_reason: cancellationReason },
        }),
      }).unwrap();
      handleClose();
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "The transaction couldn't be cancelled due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  const DROPDOWN_CANCELLATION_REASONS = [
    'Negotiation fell through',
    'Completing payment elsewhere',
    'Need to make changes',
    'Other party is unresponsive',
    'Duplicate or test',
    'Other (Please specify)',
  ];

  const isCustomReasonLengthValid = textInput.length <= 100;

  const isTextFieldInputValid = isCustomReasonLengthValid;

  const textFieldColors = !isTextFieldInputValid
    ? theme.palette.error.main
    : theme.palette.secondaryLight.dark;

  const helperText = !isCustomReasonLengthValid
    ? 'Cancellation reason must not exceed 100 characters.'
    : null;

  return (
    <React.Fragment>
      {isMobileViewport && (
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={_handleBack}
          aria-label="Close Cancel Transaction Modal"
        >
          <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
        </IconButton>
      )}
      {error && (
        <EscrowAlert>
          <AlertTitle>Unable to cancel the transaction</AlertTitle>
          {errorMsg}
        </EscrowAlert>
      )}
      <DialogTitle>
        <MediumText>Confirm transaction cancellation</MediumText>
      </DialogTitle>
      <DialogContent>
        <SmallText>
          What is the reason for canceling this transaction? Once you cancel a transaction it cannot
          be undone.
        </SmallText>
        <Box sx={{ marginTop: '24px' }}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondaryLight.dark,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: theme.palette.secondaryLight.dark,
              },
            }}
          >
            <InputLabel id="cancellation-reason-label">Cancellation reason</InputLabel>
            <Select
              label="Cancellation reason"
              value={dropdownInput}
              onChange={handleDropdownInput}
              inputProps={{ 'data-testid': 'cancellation-reason' }}
            >
              {DROPDOWN_CANCELLATION_REASONS.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {dropdownInput === 'Other (Please specify)' && (
            <TextField
              error={!isTextFieldInputValid}
              fullWidth
              label="Specific cancellation reason"
              variant="outlined"
              helperText={helperText}
              value={textInput}
              onChange={handleInput}
              inputProps={{ 'data-testid': 'custom-cancellation-reason' }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: textFieldColors,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: textFieldColors,
                },
                marginTop: '24px',
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {!isMobileViewport && <EscrowButton onClick={_handleBack}>Back</EscrowButton>}
        <EscrowButton
          sx={{ width: isMobileViewport ? '100%' : 'auto' }}
          variant="containedError"
          disabled={
            isLoading ||
            !dropdownInput ||
            (dropdownInput === 'Other (Please specify)' && !textInput) ||
            !isTextFieldInputValid
          }
          onClick={handleCancel}
        >
          Cancel transaction
        </EscrowButton>
      </DialogActions>
    </React.Fragment>
  );
};

export const CancelTransaction = ({
  transactionId,
  open,
  handleBack,
  handleClose,
  isMobileViewport,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          width: isMobileViewport ? 'auto' : '685px',
        },
      }}
      fullScreen={isMobileViewport}
    >
      <CancelTransactionContent
        transactionId={transactionId}
        handleBack={handleBack}
        handleClose={handleClose}
      />
    </Dialog>
  );
};
