import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { EscrowAlert } from 'spa/features/ui/EscrowAlert';
import { EscrowButton } from 'spa/features/ui/EscrowButton';
import { MediumText, SmallText } from 'spa/features/ui/EscrowTypography';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useForm, Controller } from 'react-hook-form';
import { usePatchTransactionMutation } from '../../transactionApi';
import { transactionSelector } from '../../transactionSlice';
import { CustomFormControl } from './CustomFormControl';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const escrowFeePayersMapping = {
  Buyer: 'buyer',
  Seller: 'seller',
  '50% Buyer / 50% Seller': 'both',
};

const currencySymbols = {
  usd: '$',
  aud: 'A$',
  gbp: '£',
  eur: '€',
  cad: 'CA$',
};

const shippingMethodMapping = {
  'Standard shipping': 'standard_shipping',
  'Cargo shipping': 'cargo_shipping',
  'No shipping needed': 'no_shipping',
};

const { TRANSACTION_TYPES } = TransactionConstants;

const ShippingFeeInput = React.forwardRef(({ value, onChange, inputProps, currency }, ref) => {
  const shippingFeeLabel = `Shipping fee (${currency.toUpperCase()})`;
  const handleBlur = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
    const formattedValue = parseFloat(sanitizedValue).toFixed(2);
    onChange(formattedValue);
  };

  const handleChange = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
    onChange(sanitizedValue);
  };

  return (
    <TextField
      ref={ref}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      label={shippingFeeLabel}
      type="text"
      InputProps={inputProps}
      data-testid="shipping-fee"
    />
  );
});

const ModifyTransactionContent = ({ transactionId, userRole, handleClose }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [patchTransaction, { isLoading, error }] = usePatchTransactionMutation();
  const transaction = useSelector(transactionSelector(transactionId));
  const [errorMsg, setErrorMsg] = useState('');
  if (!transaction) {
    return null;
  }
  const otherPartyText = userRole === 'buyer' ? 'seller' : 'buyer';
  const {
    inspectionDays,
    escrowFeePayers,
    fees,
    parties,
    concierge,
    currency,
    shippingType,
    shippingFeePayer,
    transactionTypeCode,
  } = transaction;

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      inspectionPeriod: inspectionDays,
      concierge: concierge || false,
      escrowFeePayer: escrowFeePayers.length === 1 ? escrowFeePayers[0] : 'both',
      shippingMethod: shippingType || 'no_shipping',
      shippingFeePayer: shippingFeePayer || 'seller',
      modificationReason: '',
      shippingFee: parties.buyer.email ? fees[parties.buyer.email].shippingFee || '0.00' : '0.00',
    },
  });

  const isCustomReasonLengthValid = watch('modificationReason').length <= 100;
  const isTextFieldInputValid = isCustomReasonLengthValid;
  const textFieldColors = !isTextFieldInputValid
    ? theme.palette.error.main
    : theme.palette.secondaryLight.dark;
  const helperText = !isCustomReasonLengthValid
    ? 'Reason for modification must not exceed 100 characters.'
    : null;

  const handleCloseModal = () => {
    if (isLoading) {
      return;
    }
    handleClose();
  };

  const handleConfirmModification = async (data) => {
    try {
      await patchTransaction({
        id: transactionId,
        action: 'modify_terms',
        modify_terms_information: {
          modification_reason: data.modificationReason,
          items: [
            {
              type: transactionTypeCode,
              inspection_period: data.inspectionPeriod * 86400,
              ...((transactionTypeCode === TRANSACTION_TYPES.GENERAL_MERCHANDISE ||
                transactionTypeCode === TRANSACTION_TYPES.MOTOR_VEHICLE) && {
                shipping_type: data.shippingMethod,
              }),
              ...(transactionTypeCode === TRANSACTION_TYPES.DOMAIN_NAME && {
                extra_attributes: { concierge: data.concierge },
              }),
              fees: [
                ...(data.escrowFeePayer === 'seller' && [
                  {
                    payer_customer: parties.seller.email,
                    type: 'escrow',
                    split: 1,
                  },
                ]),
                ...(data.escrowFeePayer === 'buyer' && [
                  {
                    payer_customer: parties.buyer.email,
                    type: 'escrow',
                    split: 1,
                  },
                ]),
                ...(data.escrowFeePayer === 'both' && [
                  {
                    payer_customer: parties.seller.email,
                    type: 'escrow',
                    split: 0.5,
                  },
                  {
                    payer_customer: parties.buyer.email,
                    type: 'escrow',
                    split: 0.5,
                  },
                ]),
              ],
            },
            ...(data.shippingMethod &&
              data.shippingMethod !== 'no_shipping' && [
                {
                  type: 'shipping_fee',
                  schedule: [
                    {
                      ...(data.shippingFeePayer === 'seller' && {
                        amount: 0,
                        payer_customer: parties.seller.email,
                      }),
                      ...(data.shippingFeePayer === 'buyer' && {
                        amount: data.shippingFee,
                        payer_customer: parties.buyer.email,
                      }),
                    },
                  ],
                },
              ]),
          ],
        },
      }).unwrap();
      handleClose();
    } catch (e) {
      // RTK handles the error
      let errorMsgTmp =
        "The transaction terms couldn't be modified due to a technical issue. Please refresh the page and try again.";
      if (e.xRequestId) {
        errorMsgTmp += ` If the issue persists, contact support@escrow.com and provide the id: ${e.xRequestId}`;
      }
      setErrorMsg(errorMsgTmp);
    }
  };

  return (
    <React.Fragment>
      {isMobileViewport && (
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={handleCloseModal}
          aria-label="Close Modify Transaction Modal"
        >
          <CloseIcon sx={{ color: 'mono.dark', height: 24, width: 24 }} />
        </IconButton>
      )}
      {error && (
        <EscrowAlert>
          <AlertTitle>Unable to modify transaction terms</AlertTitle>
          {errorMsg}
        </EscrowAlert>
      )}
      <DialogTitle>
        <MediumText>Modify the terms of your transaction</MediumText>
      </DialogTitle>
      <DialogContent>
        <SmallText>
          After your confirmation, we will notify the {otherPartyText} to review the updates you
          have made.
        </SmallText>
        <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(handleConfirmModification)}>
          <CustomFormControl>
            <InputLabel id="inspection-period-label">Inspection period (days)</InputLabel>
            <Controller
              name="inspectionPeriod"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="inspection-period"
                  label="Inspection period (days)"
                  data-testid="inspection-period"
                >
                  {Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </CustomFormControl>
          <CustomFormControl>
            <InputLabel id="escrow-fee-payer-label">Escrow fee payer</InputLabel>
            <Controller
              name="escrowFeePayer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="escrow-fee-payer"
                  label="Escrow fee payer"
                  data-testid="escrow-fee-payer"
                >
                  {Object.entries(escrowFeePayersMapping).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </CustomFormControl>
          {transactionTypeCode === TRANSACTION_TYPES.DOMAIN_NAME && (
            <CustomFormControl>
              <InputLabel id="concierge-label">Upgrade to Concierge Service</InputLabel>
              <Controller
                name="concierge"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="concierge"
                    label="Upgrade to Concierge Service"
                    data-testid="concierge"
                    notched
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                )}
              />
            </CustomFormControl>
          )}
          {(transactionTypeCode === TRANSACTION_TYPES.GENERAL_MERCHANDISE ||
            transactionTypeCode === TRANSACTION_TYPES.MOTOR_VEHICLE) && (
            <React.Fragment>
              <CustomFormControl>
                <InputLabel id="shipping-method-label">Shipping method</InputLabel>
                <Controller
                  name="shippingMethod"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="shipping-method"
                      label="Shipping method"
                      data-testid="shipping-method"
                    >
                      {Object.entries(shippingMethodMapping).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </CustomFormControl>
              {(watch('shippingMethod') === 'standard_shipping' ||
                watch('shippingMethod') === 'cargo_shipping') && (
                <React.Fragment>
                  <CustomFormControl>
                    <InputLabel id="shipping-fee-payer-label">Shipping fee payer</InputLabel>
                    <Controller
                      name="shippingFeePayer"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="shipping-fee-payer"
                          label="Shipping fee payer"
                          data-testid="shipping-fee-payer"
                        >
                          <MenuItem value={'buyer'}>Buyer</MenuItem>
                          <MenuItem value={'seller'}>Seller</MenuItem>
                        </Select>
                      )}
                    />
                  </CustomFormControl>
                  {watch('shippingFeePayer') === 'buyer' && (
                    <CustomFormControl>
                      <Controller
                        name="shippingFee"
                        control={control}
                        render={({ field }) => (
                          <ShippingFeeInput
                            {...field}
                            value={field.value}
                            id="buyer-shipping-fee"
                            label="Shipping fee"
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currency && currencySymbols[currency]}
                                </InputAdornment>
                              ),
                            }}
                            currency={currency}
                          />
                        )}
                      />
                    </CustomFormControl>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <CustomFormControl>
            <Controller
              name="modificationReason"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="modification-reason"
                  label="Reason for modification"
                  data-testid="modification-reason"
                  error={!isTextFieldInputValid}
                  helperText={helperText}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: textFieldColors,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: textFieldColors,
                    },
                  }}
                />
              )}
            />
          </CustomFormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        {!isMobileViewport && <EscrowButton onClick={handleCloseModal}>Cancel</EscrowButton>}
        <EscrowButton
          variant="contained"
          sx={{ width: isMobileViewport ? '100%' : 'auto' }}
          disabled={isLoading || !isTextFieldInputValid}
          onClick={handleSubmit(handleConfirmModification)}
        >
          Confirm modification
        </EscrowButton>
      </DialogActions>
    </React.Fragment>
  );
};

export const ModifyTransaction = ({ transactionId, userRole }) => {
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <EscrowButton onClick={handleOpen}>Modify transaction</EscrowButton>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          PaperProps={{
            sx: {
              width: isMobileViewport ? 'auto' : '685px',
            },
          }}
          fullScreen={isMobileViewport}
        >
          <ModifyTransactionContent
            transactionId={transactionId}
            userRole={userRole}
            handleClose={handleClose}
          />
        </Dialog>
      )}
    </React.Fragment>
  );
};
