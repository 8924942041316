import _ from 'lodash';
import React from 'react';
import TransactionConstants from 'spa/constants/TransactionConstants';

/**
 * Returns whether or not action is required from a party based on their pending actions.
 *
 * @param partyActions The action status/es of a party from the transaction object's actions_by_party property
 * @returns {bool}
 */
function checkActionRequiredFromPartyActions(partyActions) {
  if (partyActions) {
    const { ACTION_REQUIRED_STATUSES } = TransactionConstants;
    const actionTypes = Object.keys(partyActions);

    for (let i = 0; i < actionTypes.length; i++) {
      const action = actionTypes[i];
      const actionStatus = partyActions[action].status.toUpperCase();

      if (ACTION_REQUIRED_STATUSES.includes(actionStatus)) {
        return true;
      }
    }
  }

  return false;
}

function isCancelled(transaction) {
  return (
    !transaction.isDraft &&
    TransactionConstants.TRANSACTION_STATUSES.cancelled.includes(transaction.statusCode)
  );
}

/**
 * For a given party in the transaction, checks if action is required on their part.
 *
 * @param transaction The internal transaction object from /internal/transactions/search
 * @param role A string denoting the party's role. Can be buyer/seller/broker, must be lowercase.
 * @returns {bool}
 */
function checkActionRequiredForRole(transaction, role) {
  const { TRANSACTION_ROLES, TRANSACTION_STATUSES } = TransactionConstants;

  if (role === TRANSACTION_ROLES.SELLER) {
    if (transaction.isOffer) {
      // Offers are actionable by sellers
      return true;
    }
    if (transaction.isAwaitingDisbursementInfo && !isCancelled(transaction)) {
      // Transactions needing seller disbursement info are actionable for sellers
      return true;
    }
  }

  const actionsByParty = transaction.actionsByParty;
  const partyActions = actionsByParty && actionsByParty[role.toUpperCase()];
  if (partyActions && checkActionRequiredFromPartyActions(partyActions)) {
    return true;
  }

  const requiredAction = TRANSACTION_STATUSES.requireAction[role];
  if (!requiredAction) {
    return false;
  }

  if (requiredAction.transaction.includes(transaction.statusCode)) {
    return true;
  }

  // Look up if the milestone status is actionable
  if (transaction.type !== 'milestone') {
    return false;
  }

  return (
    _.intersection(
      TRANSACTION_STATUSES.requireAction[role].milestone,
      transaction.milestoneStatuses
    ).length > 0
  );
}

/**
 * Renders a status tag based on the required action for the transaction.
 *
 * @param transaction The internal transaction object from /internal/transactions/search
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ActionRequiredTag = ({ transaction }) => {
  const viewingUserRole = transaction.role;

  const { TRANSACTION_ROLES } = TransactionConstants;
  const partiesActionRequiredStatus = {};

  const parties = Object.keys(transaction.actionsByParty);
  parties.forEach((role) => {
    partiesActionRequiredStatus[role] = checkActionRequiredForRole(transaction, role.toLowerCase());
  });

  if (partiesActionRequiredStatus[viewingUserRole.toUpperCase()]) {
    return (
      <span className="transactions-tags-item transactions-tags-item--action">
        Requires your Action
      </span>
    );
  }

  const {
    [TRANSACTION_ROLES.BUYER.toUpperCase()]: buyerActionRequired,
    [TRANSACTION_ROLES.SELLER.toUpperCase()]: sellerActionRequired,
  } = partiesActionRequiredStatus;

  if (viewingUserRole === TRANSACTION_ROLES.BROKER) {
    if (buyerActionRequired && sellerActionRequired) {
      return <span className="transactions-tags-item">Both Parties Require Action</span>;
    }
  }
  if (buyerActionRequired) {
    return <span className="transactions-tags-item">Requires Buyer&apos;s Action</span>;
  }
  if (sellerActionRequired) {
    return <span className="transactions-tags-item">Requires Seller&apos;s Action</span>;
  }
  return <span />;
};

/**
 * Renders a status tag based on the current status code of a transaction. Prioritizes
 * any action required on the viewing user's end over other parties' required actions.
 *
 * @param transaction The internal transaction object from /internal/transactions/search
 * @returns {JSX.Element}
 * @constructor
 */
const StatusCodeTag = ({ transaction }) => {
  const isAwaitingAgreement = TransactionConstants.TRANSACTION_STATUSES.awaitingAgreement.includes(
    transaction.statusCode
  );
  const isAwaitingPayment = TransactionConstants.TRANSACTION_STATUSES.awaitingPayment.includes(
    transaction.statusCode
  );
  const isDnh = TransactionConstants.TRANSACTION_STATUSES.dnh.includes(transaction.statusCode);
  const isAwaitingPaymentArrival =
    TransactionConstants.TRANSACTION_STATUSES.awaitingPaymentArrival.includes(
      transaction.statusCode
    );
  const isReviewingReceivedPayment =
    TransactionConstants.TRANSACTION_STATUSES.reviewingReceivedPayment.includes(
      transaction.statusCode
    );
  const isAwaitingDelivery = TransactionConstants.TRANSACTION_STATUSES.awaitingDelivery.includes(
    transaction.statusCode
  );
  const isMilestone = TransactionConstants.TRANSACTION_STATUSES.milestone.includes(
    transaction.statusCode
  );
  const isDelivery = TransactionConstants.TRANSACTION_STATUSES.delivery.includes(
    transaction.statusCode
  );
  const isInspection = TransactionConstants.TRANSACTION_STATUSES.inspection.includes(
    transaction.statusCode
  );
  const isInDispute = transaction.isInDispute;
  const isTransactionOnHold = TransactionConstants.TRANSACTION_STATUSES.transactionOnHold.includes(
    transaction.statusCode
  );
  const isPendingCancellation =
    TransactionConstants.TRANSACTION_STATUSES.pendingCancellation.includes(transaction.statusCode);
  const isCompleted = TransactionConstants.TRANSACTION_STATUSES.completed.includes(
    transaction.statusCode
  );

  /**
   * Generally, only one of the status tags computed above will evaluate to true. But in the event that:
   * 1. More than one of them is true, we prioritize the latest status
   * 2. None of them is true, we return an empty <span> element
   */

  if (isCancelled(transaction)) {
    return <span className="transactions-tags-item">Cancelled</span>;
  }
  if (isCompleted) {
    return (
      <span className="transactions-tags-item transactions-tags-item--completed">Completed</span>
    );
  }
  if (isPendingCancellation) {
    return <span className="transactions-tags-item">Pending Cancellation</span>;
  }
  if (isTransactionOnHold) {
    return <span className="transactions-tags-item">Transaction On Hold</span>;
  }
  if (isInDispute) {
    return (
      <span className="transactions-tags-item transactions-tags-item--action">In Dispute</span>
    );
  }
  if (isInspection) {
    return (
      <span className="transactions-tags-item transactions-tags-item--ongoing">Inspection</span>
    );
  }
  if (isDelivery) {
    return <span className="transactions-tags-item transactions-tags-item--ongoing">Delivery</span>;
  }
  if (isAwaitingDelivery) {
    return (
      <span className="transactions-tags-item transactions-tags-item--ongoing">
        Awaiting Delivery
      </span>
    );
  }
  if (isMilestone) {
    return (
      <span className="transactions-tags-item transactions-tags-item--type">
        Milestone Transaction Open
      </span>
    );
  }
  if (isReviewingReceivedPayment) {
    return (
      <span className="transactions-tags-item transactions-tags-item--ongoing">
        Reviewing Received Payment
      </span>
    );
  }
  if (isAwaitingPaymentArrival) {
    return (
      <span className="transactions-tags-item transactions-tags-item--ongoing">
        Awaiting Payment Arrival
      </span>
    );
  }
  if (isDnh) {
    return <span className="transactions-tags-item transactions-tags-item--type">DNH Service</span>;
  }
  if (isAwaitingPayment) {
    return (
      <span className="transactions-tags-item transactions-tags-item--ongoing">
        Awaiting Payment
      </span>
    );
  }
  if (isAwaitingAgreement) {
    return (
      <span className="transactions-tags-item transactions-tags-item--awaiting">
        Awaiting Agreement
      </span>
    );
  }
  return <span />;
};

const TransactionTags = ({ transaction, style, includePartyActions }) => (
  <div className="transactions-tags-container" style={style}>
    {transaction.isOffer && (
      <span className="transactions-tags-item transactions-tags-item--type">Escrow Offer</span>
    )}
    <StatusCodeTag transaction={transaction} />
    {includePartyActions &&
      <ActionRequiredTag transaction={transaction} />
    }
  </div>
);
export default TransactionTags;
