import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { MediumText } from 'spa/features/ui/EscrowTypography';
import { ACTION_REQUIRED_STATUSES } from 'spa/constants/TransactionConstants';

const actionTypeToDescriptionMapping = {
  personal_verification: "Individual verification",
  company_verification: "Company verification",
  extended_verification: "Photo verification",
  account_info: "Complete account profile",
  disbursement_info: "Disbursement details",
  additional_documents: "Upload additional documents",
  funds: "Complete payment",
  goods_shipping: "Ship goods",
  goods_return: "Return goods",
  goods_receipt: "Confirm receipt of goods",
  goods_return_receipt: "Confirm receipt of returned goods",
  accept_shipment: "Accept goods",
  accept_return: "Accept returned goods",
  accept_transaction: "Accept transaction",
  sign_dnh_agreement: "Sign DNH agreement",
  "Party's Written Agreement": "Upload written agreement",
  Invoice: "Upload purchase order/invoice",
  OTHER: "Upload additional documents",
};

function isCompletedActionStatus(currentStatus) {
  return !ACTION_REQUIRED_STATUSES.includes(currentStatus) &&
    currentStatus !== "PENDING";
}

function ActionLine({actionType, actionCurrentStatus}) {
  const theme = useTheme();

  const leaderElement = isCompletedActionStatus(actionCurrentStatus) ?
    <CheckCircleIcon sx={{ color: theme.palette.primary.main, top: "-2px" }}/> :
    <RadioButtonUncheckedIcon/>;

  return (<Fragment>
    <Grid container spacing={1}>
      <Grid item>
        {leaderElement}
      </Grid>
      <Grid item>
        {actionTypeToDescriptionMapping[actionType] || actionType}
      </Grid>
    </Grid>
  </Fragment>);
}

function OutstandingActionsForParty({ actions, roleName, isMobileViewport }) {
  const theme = useTheme();

  return (
    <Grid item sx={{ width: isMobileViewport ? '100%' : '50%' }}>
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: theme.palette.mono.light,
          padding: '10px',
          height: '100%',
        }}
      >
        <Stack spacing={1}>
          <strong>{roleName} actions</strong>
          {Object.keys(actions).length === 0 && (
            <ActionLine key="completed"
                        actionType="Completed"
                        actionCurrentStatus="COMPLETE"
            />
          )}
          {Object.keys(actions).map((actionType) => {
            if (actionType !== "additional_documents" ||
                (!actions[actionType].details.pending_documents &&
                  !actions[actionType].details.required_documents)) {
              return <ActionLine
                key={actionType}
                actionType={actionType}
                actionCurrentStatus={actions[actionType].status}
              />;
            }

            return <Fragment key={actionType}>
              {actions[actionType].details.required_documents.map((doc) =>
                <ActionLine
                  key={`${actionType}--required--${doc}`}
                  actionType={doc}
                  actionCurrentStatus="REQUIRED"
                />
              )}
              {actions[actionType].details.pending_documents.map((doc) =>
                <ActionLine
                  key={`${actionType}--pending--${doc}`}
                  actionType={doc}
                  actionCurrentStatus="PENDING"
                />
              )}
            </Fragment>
          })}
        </Stack>
      </Paper>
    </Grid>
  );
}

function TransactionOutstandingActions({ transaction, isMobileViewport }) {
  const theme = useTheme();

  if (!transaction) {
    return <Fragment />;
  }

  const actions = transaction.actions_by_party;
  const buyerActions = actions.BUYER;
  const sellerActions = actions.SELLER;

  return (
    <Fragment>
      <div style={{ marginBottom: '1em' }}>
        <GppMaybeOutlinedIcon
          style={{
            color: theme.palette.secondaryLight.dark,
            width: '1.5em',
            marginRight: '3px',
            marginBottom: '-5px',
            marginLeft: '-1px',
          }}
        />
        <MediumText sx={{ display: 'inline', marginBottom: '10px' }}>
          Outstanding actions
        </MediumText>
      </div>
      To secure the payment, the buyer and the seller must still complete the following actions.
      <Grid
        container
        direction={ isMobileViewport ? "column" : "row" }
        spacing={1.5}
        columns={ isMobileViewport ? 1 : 2 }
        sx={{ width: '100%', marginTop: '8px' }}
      >
        <OutstandingActionsForParty
          actions={buyerActions}
          roleName="Buyer"
          isMobileViewport={isMobileViewport}
        />
        <OutstandingActionsForParty
          actions={sellerActions}
          roleName="Seller"
          isMobileViewport={isMobileViewport}
        />
      </Grid>
    </Fragment>
  );
}

export default TransactionOutstandingActions;
