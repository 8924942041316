import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Stack, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { KYC_BUTTONS } from 'spa/constants/VerificationConstants';
import {
  saveCurrentPage,
  pushPreviousPage,
  popPreviousPage,
  kycCurrentPageSelector,
  kycPreviousPagesSelector,
} from 'spa/features/kyc/kycSlice';
import { LargeText, SmallText } from 'spa/features/ui/EscrowTypography';
import { EscrowButton } from 'spa/features/ui/EscrowButton';

const DesktopPage = ({
  children,
  title,
  subtitle,
  ctaText,
  onSubmit,
  nextPage,
  goToNextPage,
  noButton,
  extraButtons,
}) => {
  const theme = useTheme();

  const escrowButtonStyle = useMemo(() => {
    const buttonWidth = extraButtons && extraButtons.length > 0 ? 'auto' : '160px';
    return {
      padding: '12px',
      fontSize: '18px',
      width: buttonWidth,
      maxWidth: '160px',
      margin: 'auto',
      borderRadius: '8px',
    };
  }, [extraButtons]);

  const currentPage = useSelector(kycCurrentPageSelector);
  const previousPages = useSelector(kycPreviousPagesSelector);

  const titleRef = useRef();

  const dispatch = useDispatch();

  const onNextPage = useCallback(
    (page) => {
      dispatch(pushPreviousPage({ page: currentPage }));
      dispatch(saveCurrentPage({ page }));
    },
    [currentPage, dispatch]
  );

  const onPrevPage = () => {
    dispatch(
      saveCurrentPage({ page: previousPages[previousPages.length ? previousPages.length - 1 : ''] })
    );
    dispatch(popPreviousPage());
  };

  useEffect(() => {
    if (goToNextPage) {
      onNextPage(goToNextPage);
    }
  }, [goToNextPage, onNextPage]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        minHeight: '50vh',
        width: 'auto',
        minWidth: '30vw',
        margin: '5rem 15rem',
        padding: '24px 24px 40px',
        border: '0px',
        backgroundColor: theme.palette.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Grid sx={{ paddingBottom: '24px', paddingTop: '24px', width: '100%' }} item>
        <Stack sx={{ gap: '24px' }} direction="column">
          <Grid container direction="row" sx={{ alignItems: 'center' }}>
            <Grid item xs="auto" alignItems="flex-start" sx={{ width: 'fit-content' }}>
              <IconButton onClick={onPrevPage} sx={{ padding: '0px' }}>
                <ArrowBack sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Grid>
            <LargeText ref={titleRef} marginLeft="2rem" marginRight="2rem">
              {title}
            </LargeText>
          </Grid>
          <Stack direction="column" sx={{ gap: '8px' }}>
            <SmallText sx={{ fontSize: '16px' }}> {subtitle} </SmallText>
          </Stack>
          {children}
        </Stack>
      </Grid>
      {!noButton && (onSubmit || nextPage) ? (
        <Grid container direction="row" justifyContent="center">
          {extraButtons && extraButtons.includes(KYC_BUTTONS.ResendSMS) && (
            <EscrowButton
              onClick={onPrevPage}
              variant="text"
              sx={{
                ...escrowButtonStyle,
                textDecoration: 'underline',
                backgroundColor: 'transparent !important',
              }}
            >
              {KYC_BUTTONS.ResendSMS}
            </EscrowButton>
          )}
          {ctaText && (
            <EscrowButton
              onClick={(data) => {
                onSubmit ? onSubmit(data) : null;
                nextPage ? onNextPage(nextPage) : null;
              }}
              variant="contained"
              color="primary"
              sx={{ ...escrowButtonStyle }}
            >
              {ctaText}
            </EscrowButton>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DesktopPage;
