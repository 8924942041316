import React from 'react';

import PropTypes from 'prop-types';
import * as _ from 'lodash';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { EscrowPaper } from '../../../ui/EscrowPaper';
import { SmallText, MediumText } from '../../../ui/EscrowTypography';
import { EscrowButton } from '../../../ui/EscrowButton';
import { ctaMapping } from './InstructionMapping';

const InstructionDescription = ({ description, inspectionDays, sx, role }) => {
  const substitutions = [
    { substitute: '{inspectionDays}', value: inspectionDays },
    { substitute: '{day/s}', value: inspectionDays > 1 ? 'days' : 'day' },
    // Seller if buyer, seller otherwise
    { substitute: '{otherParty}', value: role === 'buyer' ? 'seller' : 'buyer' },
  ];
  const descriptionSubbed = substitutions.reduce(
    (string, substitution) => string.replace(substitution.substitute, substitution.value),
    description
  );

  const descriptionElements = [];
  let prevIdx = 0;
  for (const match of descriptionSubbed.matchAll(/\*\*(.+?)\*\*/g)) {
    descriptionElements.push(
      <React.Fragment key={descriptionElements.length}>
        {descriptionSubbed.substring(prevIdx, match.index)}
      </React.Fragment>
    );
    descriptionElements.push(
      <SmallText key={descriptionElements.length} component="span" variant="bold">
        {match[1]}
      </SmallText>
    );
    prevIdx = match.index + match[0].length;
  }
  descriptionElements.push(descriptionSubbed.substring(prevIdx));

  return <SmallText sx={sx}>{descriptionElements}</SmallText>;
};

const TransactionInstruction = ({
  transactionId,
  instruction,
  inspectionDays,
  sx,
  userRole,
  disbursementMethods,
  shippingInformation,
  paymentLink,
  listingReference,
}) => {
  const { title, description, primaryCta, secondaryCtas, alternateCta, descriptionHtml } =
    instruction;

  let renderPrimaryCta;
  let secondaryCtaComponents;
  let renderAlternateCta;
  if (primaryCta) {
    renderPrimaryCta = _.get(ctaMapping, instruction.primaryCta, () => (
      <EscrowButton
        data-testid="instruction-cta-primary"
        variant="contained"
        sx={{ width: { xs: '100%', sm: 'auto' } }}
      >
        {instruction.primaryCta}
      </EscrowButton>
    ));
  }
  if (secondaryCtas) {
    secondaryCtaComponents = instruction.secondaryCtas.map((cta, idx) => {
      let component = (
        <EscrowButton
          key={cta}
          data-testid={`instruction-cta-secondary-${idx}`}
          variant="outlined"
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          {cta}
        </EscrowButton>
      );
      if (ctaMapping[cta]) {
        component = ctaMapping[cta]({
          transactionId,
          userRole,
          inspectionDays,
          disbursementMethods,
          paymentLink,
          listingReference,
        });
      }
      return <React.Fragment key={cta}>{component}</React.Fragment>;
    });
  }
  if (alternateCta) {
    renderAlternateCta = _.get(ctaMapping, alternateCta, () => (
      <EscrowButton
        data-testid="instruction-cta-alternate"
        sx={{ width: { xs: '100%', sm: 'auto' } }}
      >
        {instruction.alternateCta}
      </EscrowButton>
    ));
  }
  return (
    <EscrowPaper
      sx={{
        flexDirection: 'column',
        borderColor: 'secondaryLight.dark',
        backgroundImage: 'url(../../../../build/images/global/icons/escrow-shield-background.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '110% 50%',
        ...sx,
      }}
      data-sentry-unmask="true"
    >
      <Stack alignItems="flex-start">
        <MediumText data-testid="instruction-title">{title}</MediumText>
        {descriptionHtml !== undefined && <Box sx={{ marginTop: '8px' }}>{descriptionHtml}</Box>}
        {descriptionHtml === undefined && description && (
          <InstructionDescription
            data-testid="instruction-description"
            sx={{ marginTop: '8px' }}
            description={description}
            inspectionDays={inspectionDays}
            role={userRole}
          />
        )}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing="8px"
          marginTop="24px"
          sx={{
            width: '100%',
          }}
        >
          {renderPrimaryCta &&
            renderPrimaryCta({
              transactionId,
              userRole,
              inspectionDays,
              disbursementMethods,
            })}
          {secondaryCtaComponents}
          {renderAlternateCta &&
            renderAlternateCta({
              transactionId,
              userRole,
              inspectionDays,
              disbursementMethods,
            })}
          {!renderPrimaryCta && !secondaryCtaComponents && !renderAlternateCta && (
            <SmallText variant="bold">No action is required from you for now.</SmallText>
          )}
        </Stack>
        {shippingInformation}
      </Stack>
    </EscrowPaper>
  );
};

TransactionInstruction.propTypes = {
  transactionId: PropTypes.string,
  instruction: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    descriptionHtml: PropTypes.element,
    primaryCta: PropTypes.string,
    secondaryCtas: PropTypes.arrayOf(PropTypes.string),
    alternateCta: PropTypes.string,
  }),
  inspectionDays: PropTypes.number,
  sx: PropTypes.object,
  userRole: PropTypes.string,
  disbursementMethods: PropTypes.object,
  paymentLink: PropTypes.string,
  listingReference: PropTypes.string,
};

export default TransactionInstruction;
