import React, { useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { useTheme } from '@mui/material/styles';

import { EscrowPaper } from '../../../ui/EscrowPaper';
import { SmallText, MediumText, SmallLink } from '../../../ui/EscrowTypography';
import { urlfor } from '../../../../../utils/filters';

const TransactionFaqs = ({ mapping, transactionStatus, transactionType }) => {
  const theme = useTheme();
  const backgroundColor = theme.palette.secondaryLight.xlight;

  const faqItems = useMemo(() => {
    if (mapping === undefined) return [];
    const filteredFaqItems = mapping.filter(
      (item) =>
        item.transactionStatus === transactionStatus &&
        (!item.transactionType || item.transactionType.includes(transactionType))
    );
    return filteredFaqItems.length > 0 ? filteredFaqItems[0].faqs : [];
  }, [mapping, transactionStatus, transactionType]);

  return (
    <EscrowPaper sx={{ backgroundColor }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '16px',
          backgroundColor,
        }}
      >
        <ContactSupportOutlinedIcon
          sx={{ color: 'secondaryLight.dark', height: 20, width: 20 }}
          data-testid="faqIcon"
        />
        <MediumText>Frequently asked questions</MediumText>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {faqItems &&
          faqItems.map((faq) => {
            return (
              <Accordion
                elevation={0}
                disableGutters
                sx={{ borderBottom: '1px solid #0000001f', width: '100%', backgroundColor }}
                key={faq.title}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.dark' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ padding: 0 }}
                  data-testid={`faqItem-${faq.title}`}
                >
                  <SmallText>{faq.title}</SmallText>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <SmallText sx={{ marginBottom: '12px' }}>{faq.description}</SmallText>
                  <SmallText sx={{ marginBottom: '12px' }}>
                    To view more information about Escrow.com’s process, click{' '}
                    <SmallLink href={urlfor(faq.redirectPath)}>here</SmallLink>.
                  </SmallText>
                </AccordionDetails>
              </Accordion>
            );
          })}
        <Box sx={{ display: 'flex', alignItems: 'center', height: '48px', width: '100%' }}>
          <SmallLink href="/helpdesk">Need more help?</SmallLink>
        </Box>
      </Box>
    </EscrowPaper>
  );
};

export default TransactionFaqs;
