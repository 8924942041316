import { createAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getWindow } from '../../../utils/globals';

const window = getWindow();

export const transactionFetched = createAction('transactionApi/transactionFetched');

function invalidateOnSuccessFunc(tags) {
  return (result, error) => (error ? [] : tags);
}

function transformErrorResponse(response, meta) {
  return {
    ...response,
    xRequestId: meta && meta.response ? meta.response.headers.get('x-request-id') : undefined,
  };
}

export const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.config.v4_api_endpoint,
    credentials: 'include',
  }),
  tagTypes: ['Transaction'],
  endpoints: (builder) => ({
    getTransaction: builder.query({
      query: (id) => ({
        url: `/transaction/${id}`,
        params: { show_cancelled: true },
      }),
      transformErrorResponse,
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          const { data: transaction } = await queryFulfilled;
          dispatch(transactionFetched({ id, transaction }));
        } catch (error) {
          // ignore
        }
      },
      providesTags: ['Transaction'],
    }),
    searchTransactionById: builder.query({
      query: (id) => ({
        url: `${window.config.internal_endpoint}/transactions/search`,
        params: {
          transaction_id: id,
          limit: 1,
          include_actions: true,
        },
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.transactions[0];
      },
      transformErrorResponse,
      providesTags: ['Transaction'],
    }),
    getTransactionExtraDetails: builder.query({
      query: (id) => `/transaction/${id}/extra_details`,
      transformErrorResponse,
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          const { data: extraDetails } = await queryFulfilled;
          dispatch(transactionFetched({ id, extraDetails }));
        } catch (error) {
          // ignore
        }
      },
      providesTags: ['Transaction'],
    }),
    getTransactionHistory: builder.query({
      query: (id) => `/transaction/${id}/timeline-entries`,
      transformErrorResponse,
      providesTags: ['Transaction'],
    }),
    getTransactionSteps: builder.query({
      query: (id) => `/transaction/${id}/steps`,
      transformErrorResponse,
      providesTags: ['Transaction'],
    }),
    getTransactionPaymentMethods: builder.query({
      query: (id) => `/transaction/${id}/payment_methods`,
      transformErrorResponse,
    }),
    getTransactionWireInstructions: builder.query({
      query: (id) => `/transaction/${id}/payment_methods/wire_transfer`,
    }),
    patchTransaction: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/transaction/${id}`,
        method: 'PATCH',
        body: data,
      }),
      transformErrorResponse,
      invalidatesTags: invalidateOnSuccessFunc(['Transaction']),
    }),
    getTransactionDisbursementMethods: builder.query({
      query: (id) => `/transaction/${id}/disbursement_methods`,
      transformErrorResponse,
      providesTags: ['Transaction'],
    }),
    setTransactionDisbursementMethod: builder.mutation({
      query: ({ transId, ...data }) => ({
        url: `/transaction/${transId}/disbursement_methods`,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse,
      invalidatesTags: invalidateOnSuccessFunc(['Transaction']),
    }),
    getRequestedAdditionalDocuments: builder.query({
      query: (transactionId, customerId) => ({
        url: `${window.config.www_api_url}/api/additional-docs/requests`,
        params: { trans_id: transactionId, customer_id: customerId },
      }),
    }),
    getVerificationStatus: builder.query({
      query: (userEmail) => ({
        url: '/customer/verification_status',
        params: { email: userEmail },
      }),
    }),
    getLatestTransactionStatusHistory: builder.query({
      query: (transactionId) => `/transaction/${transactionId}/status_history/latest`,
      transformErrorResponse,
      providesTags: ['Transaction'],
    }),
  }),
});

export const {
  useGetTransactionQuery,
  useGetTransactionExtraDetailsQuery,
  useGetTransactionHistoryQuery,
  useGetTransactionStepsQuery,
  useGetTransactionPaymentMethodsQuery,
  useGetTransactionWireInstructionsQuery,
  usePatchTransactionMutation,
  useGetTransactionDisbursementMethodsQuery,
  useSetTransactionDisbursementMethodMutation,
  useGetRequestedAdditionalDocumentsQuery,
  useGetVerificationStatusQuery,
  useGetLatestTransactionStatusHistoryQuery,
  useSearchTransactionByIdQuery,
} = transactionApi;
